/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react'

import mintSuccessIconGroup from '../../assets/mint-success-icon-group.svg'
import mintSuccessIcon from '../../assets/mint-success-icon.svg'
import viewExplorer from '../../assets/view-explorer.svg'
import rightArrowIcon from '../../assets/arrow-right.svg'
import { TCollateral, TMINT_SCREEN } from '../../types'
import { formatStringToNumber } from '../../utils'
import USN from '../../assets/USN.svg'

interface IProps {
  collateralAmount: string
  selectedCollateral: TCollateral
  setScreen: (screen: TMINT_SCREEN) => void
  setCollateralAmount: (value: string) => void
  selectCollateral: (colateral: TCollateral) => void
}

const MintSuccessful = (props: IProps) => {
  const isActionDisabled = useMemo(
    () => !props.collateralAmount || !Number(props.collateralAmount),
    [props, props.collateralAmount],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.collateralAmount) ? props.collateralAmount : ''),
    [props, formatStringToNumber],
  )

  return (
    <>
      <div className="w-[72px] h-[72px] rounded-[64px] bg-green-200 p-4">
        <img src={mintSuccessIcon} className="w-full h-full" alt="Success" />
      </div>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        You have Minted your <br />
        <div className="w-fit flex items-center justify-between gap-2">
          <div className="w-10 h-10">
            <img src={USN} className="w-full h-full" alt="USN" />
          </div>
          <p>{formattedUSNOutputAmount} USN</p>
        </div>
      </p>

      <div className="relative rounded-lg bg-stone-100 h-44 w-ful flex flex-col gap-2 py-[14px] px-4 items-start justify-between overflow-hidden">
        <div className="absolute -right-7 -bottom-12 md:right-0 md:-bottom-2 p-0 m-0 max-w-[46%] h-full max-h-full">
          <img src={mintSuccessIconGroup} alt="USN icons" className="w-full h-full p-0 m-0" />
        </div>
        <p className="min-h-fit font-Suisse-Intl font-medium text-2xl text-stone-500 text-wrap">
          Stake your USN to <br /> earn extra rewards
        </p>
        <div className="w-fit flex items-center justify-center p-2">
          <p className="font-Suisse-Intl font-medium text-base text-stone-900">Stake Now</p>
          <div className="w-6 h-6">
            <img src={rightArrowIcon} className="w-full h-full" alt="Arrow" />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <button
          disabled={isActionDisabled}
          onClick={() => {}}
          className="grow w-full md:w-auto flex rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] bg-stone-100 border-stone-300 items-center justify-center"
        >
          <p className="font-Suisse-Intl font-medium text-base text-stone-900">View Explorer</p>
          <div className="w-6 h-6">
            <img src={viewExplorer} className="w-full h-full" alt="Explorer" />
          </div>
        </button>

        <button
          onClick={() => props.setScreen('input')}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Mint More USN
        </button>
      </div>
    </>
  )
}

export default MintSuccessful

/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react'

import mintSuccessIcon from '../../assets/mint-success-icon.svg'
import infoCircle from '../../assets/information-circle.svg'
import arrowRight from '../../assets/arrow-right-faded.svg'
import viewExplorer from '../../assets/view-explorer.svg'
import { TMINT_SCREEN, TSTAKE_TAB } from '../../types'
import { formatStringToNumber } from '../../utils'
import USNs from '../../assets/USNs.svg'
import USN from '../../assets/USN.svg'

interface IProps {
  usnAmount: string
  usnsAmount: string
  txnExplorerURL: string
  setTab: (tab: TSTAKE_TAB) => void
  setScreen: (screen: TMINT_SCREEN) => void
}

const UnStakeSuccessful = (props: IProps) => {
  const isActionDisabled = useMemo(
    () => !props.usnAmount || !Number(props.usnAmount) || !props.usnsAmount || !Number(props.usnsAmount),
    [props],
  )

  const formattedUSNsInputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnsAmount) ? props.usnsAmount : ''),
    [props, formatStringToNumber],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnAmount) ? props.usnAmount : ''),
    [props, formatStringToNumber],
  )

  return (
    <>
      <div className="w-[72px] h-[72px] rounded-[64px] bg-green-200 p-4">
        <img src={mintSuccessIcon} className="w-full h-full" alt="Success" />
      </div>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        You have successfully raised <br /> unstake request
      </p>

      <div className="w-full flex flex-col rounded-xl p-2 gap-3 bg-stone-100 items-start justify-center">
        <div className="w-full h-full flex flex-col md:flex-row items-center justify-between gap-2">
          <div className="grow w-full md:max-w-[49%] md:h-full rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">You&apos;re unstaking</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={USNs} className="w-full h-full" alt="USNs" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center">
                {formattedUSNsInputAmount} USN
              </p>
            </div>
          </div>

          <div className="grow w-full md:max-w-[49%] md:h-full rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">To get back</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={USN} className="w-full h-full" alt="USNs" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center">
                {formattedUSNOutputAmount} USN
              </p>
            </div>
          </div>
        </div>

        <div className="w-full flex items-start justify-start rounded-[10px] p-2 gap-2 bg-stone-100">
          <div className="w-6 h-6 shrink-0">
            <img src={infoCircle} alt="Tooltip" className="w-full h-full" />
          </div>
          <div className="flex flex-col items-start justify-center gap-[2px] shrink">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap">
              Your withdraw will be available in 7 days
            </p>
            <a href={''} className="flex items-center justify-center outline-none gap-1 text-wrap">
              <p className="font-Suisse-Intl font-[450] text-base text-stone-900 underline text-wrap">Track Progress</p>
              <div className="w-6 h-6 shrink-0">
                <img src={arrowRight} alt="Track" className="w-full h-full" />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <a
          href={isActionDisabled ? '' : props.txnExplorerURL || ''}
          className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] bg-stone-100 border-stone-300 items-center justify-center ${isActionDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p className="font-Suisse-Intl font-medium text-base text-stone-900">View Explorer</p>
          <div className="w-6 h-6">
            <img src={viewExplorer} className="w-full h-full" alt="Explorer" />
          </div>
        </a>

        <button
          onClick={() => {
            props.setTab('stake')
            props.setScreen('input')
          }}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Stake More
        </button>
      </div>
    </>
  )
}

export default UnStakeSuccessful

/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'

import { TSTAKE_SCREEN, TSTAKE_TAB } from '../../types'
import WithdrawSuccessful from './WithdrawSuccessful'
import WithdrawalPeriod from './WithdrawalPeriod'

interface IProps {
  screen: TSTAKE_SCREEN
  setTab: (tab: TSTAKE_TAB) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
}

const Withdraw = (props: IProps) => {
  const [txnExplorerURL, setTxnExplorerURL] = useState<string>('')
  const [usnWithdrawable, setUSNWithdrawable] = useState<string>('')
  const [usnsWithdrawable, setUSNsWithdrawable] = useState<string>('')

  return props.screen !== 'success' ? (
    <WithdrawalPeriod
      screen={props.screen}
      setScreen={props.setScreen}
      setUSNWithdrawable={setUSNWithdrawable}
      setUSNsWithdrawable={setUSNsWithdrawable}
      setTxnExplorerURL={setTxnExplorerURL}
    />
  ) : (
    <WithdrawSuccessful
      usnWithdrawn={usnWithdrawable}
      usnsWithdrawn={usnsWithdrawable}
      setTab={props.setTab}
      setScreen={props.setScreen}
      txnExplorerURL={txnExplorerURL}
    />
  )
}

export default Withdraw

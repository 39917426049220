/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import Countdown from 'react-countdown'
import { zeroAddress } from 'viem'

import { waitForTransactionReceipt } from '@wagmi/core'

import { useEffect, useMemo, useState } from 'react'

import readyForWithdrawalPNG from '../../assets/ready-for-withdrawal.png'
import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import withdrawIcon from '../../assets/withdraw-button-icon.svg'
import { STAKING_VAULT_ADDRESS } from '../../constants'
import { ETxnStatus, TSTAKE_SCREEN } from '../../types'
import { formatBigNumber } from '../../utils'
import clock from '../../assets/clock.svg'
import USNs from '../../assets/USNs.svg'

interface IProps {
  screen: TSTAKE_SCREEN
  setTxnExplorerURL: (url: string) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
  setUSNWithdrawable: (amount: string) => void
  setUSNsWithdrawable: (amount: string) => void
}

const WithdrawalPeriod = (props: IProps) => {
  const [confirmButtonText, setConfirmButtonText] = useState<string>('Withdraw')
  const [withdrawStatus, setWithdrawStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const withdrawalRequest = useReadContract({
    abi: StakingVaultABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'withdrawalDemands',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const withdrawalPeriod = useReadContract({
    abi: StakingVaultABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'withdrawPeriod',
    args: [],
    config: CHAIN_NETWORK_CONFIG,
  })

  const currentTimestamp = useMemo(() => {
    return Date.now()
  }, [props, account])

  const withdrawalAmountBigint = useMemo(() => {
    const withdrawalReqData = (withdrawalRequest.data ?? []) as bigint[]
    return withdrawalReqData?.at(1) ?? BigInt(0)
  }, [props, account, withdrawalRequest])

  const withdrawalAmountFormatted = useMemo(() => {
    return formatBigNumber(withdrawalAmountBigint, 18)
  }, [props, account, withdrawalRequest, withdrawalAmountBigint])

  const canWithdrawTimestamp = useMemo(() => {
    const period = (withdrawalPeriod.data as bigint) ?? BigInt(0)
    const withdrawalReqData = (withdrawalRequest.data ?? []) as bigint[]
    const withdrawalReqTimestampBigNumber = withdrawalReqData?.at(0) ?? BigInt(0)
    const _canWithdrawTimestamp = withdrawalReqTimestampBigNumber + period
    return Number(_canWithdrawTimestamp.toString() ?? '0') * 1000
  }, [props, account, withdrawalRequest, withdrawalPeriod])

  const redeemedUSN = useReadContract({
    abi: StakingVaultABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'previewRedeem',
    args: [withdrawalAmountBigint ?? BigInt(0)],
    config: CHAIN_NETWORK_CONFIG,
  })

  const isActionDisabled = useMemo(
    () =>
      !account || !account.address || withdrawalAmountBigint === BigInt(0) || canWithdrawTimestamp > currentTimestamp,
    [account, props],
  )

  useEffect(() => {
    props.setUSNsWithdrawable(formatBigNumber(withdrawalAmountBigint, 18, false))
  }, [withdrawalAmountBigint])

  useEffect(() => {
    const val = (redeemedUSN.data as bigint) ?? BigInt(0)
    props.setUSNWithdrawable(formatBigNumber(val, 18, false))
  }, [redeemedUSN])

  const withdrawUSNs = async () => {
    if (!account.isConnected) {
      setWithdrawStatus(ETxnStatus.TXN_FAILURE)
      setConfirmButtonText('Try again')
      return
    }

    setWithdrawStatus(ETxnStatus.PROCESSING)

    writeContract(
      {
        abi: StakingVaultABI,
        address: STAKING_VAULT_ADDRESS,
        functionName: 'redeem',
        args: [withdrawalAmountBigint ?? BigInt(0), account?.address ?? zeroAddress, account?.address ?? zeroAddress],
      },
      {
        onError: () => {
          setWithdrawStatus(ETxnStatus.TXN_FAILURE)
          setConfirmButtonText('Try again')
          setTimeout(() => {
            setWithdrawStatus(ETxnStatus.IDLE)
            setConfirmButtonText('Withdraw')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setWithdrawStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setConfirmButtonText('Withdrawing...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          if (receipt.status === 'success') {
            setWithdrawStatus(ETxnStatus.TXN_SUCCESS)
            setConfirmButtonText('Withdrawn')
            props.setTxnExplorerURL(`${account.chain?.blockExplorers.default.url}/tx/${receipt.transactionHash}`)
            props.setScreen('success')
          } else {
            setWithdrawStatus(ETxnStatus.TXN_FAILURE)
            setConfirmButtonText('Try again')
            setTimeout(() => {
              setWithdrawStatus(ETxnStatus.IDLE)
              setConfirmButtonText('Withdraw')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <div className="w-full flex flex-col items-center justify-center gap-6">
      <div className="w-full flex flex-col items-center justify-center gap-1">
        <p className="w-full font-Louize font-normal text-4xl text-center tracking-tighter text-stone-900">
          {canWithdrawTimestamp > currentTimestamp ? (
            <>
              You can withdraw your <br /> funds to your wallet in
            </>
          ) : (
            <>
              Your funds are ready for <br /> withdrawal{' '}
            </>
          )}
        </p>

        <div className="w-36 h-36">
          {canWithdrawTimestamp > currentTimestamp ? (
            <img src={clock} className="w-full h-full" alt="Clock" />
          ) : (
            <img src={readyForWithdrawalPNG} className="w-full h-full" alt="Clock" />
          )}
        </div>

        {canWithdrawTimestamp > currentTimestamp && (
          <p className="w-full font-Suisse-Intl font-medium text-5xl text-center text-stone-900">
            <Countdown date={canWithdrawTimestamp} />
          </p>
        )}
      </div>

      <div className="w-full flex flex-col items-center justify-center gap-[2px]">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">WITHDRAW AMOUNT</p>
        <div className="flex items-center justify-center gap-1">
          <div className="w-6 h-6">
            <img src={USNs} className="w-full h-full" alt="USNs" />
          </div>
          <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">{withdrawalAmountFormatted} USNs</p>
        </div>
      </div>

      <button
        onClick={withdrawUSNs}
        disabled={
          isActionDisabled ||
          withdrawStatus === ETxnStatus.PROCESSING ||
          withdrawStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full flex items-center justify-center rounded-[184px] gap-[6px] py-3 px-[18px] ${isActionDisabled ? ' bg-indigo-400' : 'bg-indigo-600'}`}
      >
        <div className="w-6 h-6">
          <img src={withdrawIcon} className="w-full h-full" alt="Clock" />
        </div>
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? ' text-indigo-200' : 'text-stone-50'}`}
        >
          {canWithdrawTimestamp > currentTimestamp ? 'Your withdrawal is in progress...' : confirmButtonText}
        </p>
      </button>
    </div>
  )
}

export default WithdrawalPeriod

import { formatUnits } from 'viem'

export const formatBigNumber = (value: bigint, decimals: number, humanize: boolean = true) => {
  if (!value) {
    return '0.00'
  }

  const val = formatUnits(value ?? BigInt(0), decimals) ?? '0.00'

  return humanize
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 2,
      }).format(val as `${number}`)
    : val.toString()
}

export const formatStringToNumber = (value: string, humanize: boolean = true) => {
  if (!value) {
    return 0
  }

  const val = Number(value)

  return humanize
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 2,
      }).format(val)
    : val.toString()
}

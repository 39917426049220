/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react'

import mintSuccessIcon from '../../assets/mint-success-icon.svg'
import viewExplorer from '../../assets/view-explorer.svg'
import { TMINT_SCREEN, TSTAKE_TAB } from '../../types'
import { formatStringToNumber } from '../../utils'
import USNs from '../../assets/USNs.svg'

interface IProps {
  usnWithdrawn: string
  usnsWithdrawn: string
  txnExplorerURL: string
  setTab: (tag: TSTAKE_TAB) => void
  setScreen: (screen: TMINT_SCREEN) => void
}

const WithdrawSuccessful = (props: IProps) => {
  const isActionDisabled = useMemo(() => !props.usnsWithdrawn, [props])

  const formattedUSNsWithdrawn = useMemo(
    () => formatStringToNumber(Number(props.usnsWithdrawn) ? props.usnsWithdrawn : ''),
    [props, formatStringToNumber],
  )

  return (
    <>
      <div className="w-[72px] h-[72px] rounded-[64px] bg-green-200 p-4">
        <img src={mintSuccessIcon} className="w-full h-full" alt="Success" />
      </div>

      <div className="flex flex-col items-start justify-center">
        <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">You have withdrawn your</p>
        <div className="w-fit flex items-start justify-center gap-2">
          <div className="w-10 h-10">
            <img alt="USNs" className="w-full h-full" src={USNs} />
          </div>
          <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
            {formattedUSNsWithdrawn} USNs
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <a
          href={isActionDisabled ? '' : props.txnExplorerURL || ''}
          className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] bg-stone-100 border-stone-300 items-center justify-center ${isActionDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p className="font-Suisse-Intl font-medium text-base text-stone-900">View Explorer</p>
          <div className="w-6 h-6">
            <img src={viewExplorer} className="w-full h-full" alt="Explorer" />
          </div>
        </a>

        <button
          onClick={() => {
            props.setTab('stake')
            props.setScreen('input')
          }}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Stake More
        </button>
      </div>
    </>
  )
}

export default WithdrawSuccessful

/* eslint-disable react/react-in-jsx-scope */

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Dashboard from '../../pages/Dashboard'
import StakingPage from '../../pages/Staking'
import MintingPage from '../../pages/Minting'
import Page from '../Page/Page'
import Navbar from '../Navbar'
import Footer from '../Footer'

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Page>
        <Routes>
          <Route path="/" element={<MintingPage />} />
          <Route path="/staking" element={<StakingPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Page>
      <Footer />
    </BrowserRouter>
  )
}

export default App

/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useReadContract } from 'wagmi'
import { useMemo, useState } from 'react'
import { zeroAddress } from 'viem'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import ProtocolInfo from '../../components/ProtocolInfo/ProtocolInfo'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { STAKING_VAULT_ADDRESS } from '../../constants'
import { TSTAKE_SCREEN, TSTAKE_TAB } from '../../types'
import StakeInfo from '../../components/StakeInfo'
import Withdraw from '../../components/Withdraw'
import UnStake from '../../components/Unstake'
import Stake from '../../components/Stake'

const StakingPage = () => {
  const [tab, setTab] = useState<TSTAKE_TAB>('stake')
  const [screen, setScreen] = useState<TSTAKE_SCREEN>('input')

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })

  const withdrawalRequest = useReadContract({
    abi: StakingVaultABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'withdrawalDemands',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const withdrawalAmountBigint = useMemo(() => {
    const withdrawalReqData = (withdrawalRequest.data ?? []) as bigint[]
    return withdrawalReqData?.at(1) ?? BigInt(0)
  }, [account, withdrawalRequest])

  return (
    <div className="h-fit flex flex-col md:grid md:grid-cols-2 gap-6 py-10 px-4 md:px-20 md:py-32 items-center justify-start md:items-start md:justify-between">
      <ProtocolInfo page="Staking" />
      <div className="w-full md:w-[73%] max-w-full flex flex-col rounded-xl border-[1px] border-stone-300 justify-self-end">
        <StakeInfo />

        <div className="w-full flex flex-col py-4 px-3 md:p-6 gap-6 bg-white border-none stake-container-shadow rounded-b-xl">
          {screen === 'input' && (
            <div className="w-fit flex gap-[2px] p-[2px] rounded-xl bg-stone-100 items-center justify-between">
              <button
                onClick={() => setTab('stake')}
                className={`w-fit rounded-[10px] py-2 px-4 ${tab === 'stake' ? 'stake-tab-shadow bg-stone-50' : ''} font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text`}
              >
                Stake
              </button>
              <button
                onClick={() => setTab('unstake')}
                className={`w-fit rounded-[10px] py-2 px-4 ${tab === 'unstake' ? 'stake-tab-shadow bg-stone-50' : ''} font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text`}
              >
                Unstake
              </button>
              <button
                disabled={withdrawalAmountBigint === BigInt(0)}
                onClick={() => setTab('withdraw')}
                className={`w-fit rounded-[10px] py-2 px-4 ${tab === 'withdraw' ? 'stake-tab-shadow bg-stone-50' : ''} font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text ${withdrawalAmountBigint === BigInt(0) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                Withdraw
              </button>
            </div>
          )}

          {tab === 'stake' ? (
            <Stake screen={screen} setScreen={setScreen} />
          ) : tab === 'unstake' ? (
            <UnStake screen={screen} setTab={setTab} setScreen={setScreen} />
          ) : (
            <Withdraw screen={screen} setTab={setTab} setScreen={setScreen} />
          )}
        </div>
      </div>
    </div>
  )
}

export default StakingPage

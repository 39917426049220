/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'

import InputAndApproveStake from './InputAndApproveStake'
import StakeSuccessful from './StakeSuccessful'
import { TSTAKE_SCREEN } from '../../types'
import StakeConfirm from './StakeConfirm'

interface IProps {
  screen: TSTAKE_SCREEN
  setScreen: (screen: TSTAKE_SCREEN) => void
}

const Stake = (props: IProps) => {
  const [usnAmount, setUSNAmount] = useState<string>('')
  const [usnsAmount, setUSNsAmount] = useState<string>('')
  const [txnExplorerURL, setTxnExplorerURL] = useState<string>('')

  return props.screen === 'input' ? (
    <InputAndApproveStake
      usnAmount={usnAmount}
      setUSNAmount={setUSNAmount}
      setUSNsAmount={setUSNsAmount}
      setScreen={props.setScreen}
    />
  ) : props.screen === 'confirm' ? (
    <StakeConfirm
      usnAmount={usnAmount}
      usnsAmount={usnsAmount}
      setScreen={props.setScreen}
      setTxnExplorerURL={setTxnExplorerURL}
    />
  ) : (
    <StakeSuccessful
      usnAmount={usnAmount}
      usnsAmount={usnsAmount}
      setScreen={props.setScreen}
      txnExplorerURL={txnExplorerURL}
    />
  )
}

export default Stake

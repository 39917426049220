/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom'

import telegramLogo from '../../assets/telegram.svg'
import discordLogo from '../../assets/discord.svg'
import githubLogo from '../../assets/github.svg'
import xLogo from '../../assets/x.svg'

const Footer = () => {
  return (
    <>
      <div className="hidden md:flex py-4 px-9 gap-12 items-center justify-between">
        <section className="font-normal font-Inter text-sm text-stone-500 leading-5 w-[32%] max-w-[32%]">
          © 2024 Noon Capital. All rights reserved.
        </section>
        <section className="flex gap-7 items-center justify-center justify-self-center">
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Contact
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Terms and Conditions
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Privacy Policy
          </Link>
        </section>
        <section className="flex w-[32%] max-w-[32%] gap-6 items-center justify-end">
          <div className="h-[18px] w-[18px]">
            <img src={telegramLogo} className="h-full w-full" alt="Telegram" />
          </div>
          <div className="h-[18px] w-[18px]">
            <img src={githubLogo} className="h-full w-full" alt="Github" />
          </div>
          <div className="h-[18px] w-[18px]">
            <img src={discordLogo} className="h-full w-full" alt="Discord" />
          </div>
          <div className="h-[18px] w-[18px]">
            <img src={xLogo} className="h-full w-full" alt="X" />
          </div>
        </section>
      </div>

      <div className="flex flex-col md:hidden p-4 gap-6 items-start justify-between">
        <section className="font-normal font-Inter text-sm text-stone-500 leading-5 w-full">
          © 2024 Noon Capital. All rights reserved.
        </section>
        <section className="flex gap-7 items-center justify-between justify-self-center">
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Contact
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Terms and Conditions
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Privacy Policy
          </Link>
        </section>
        <section className="flex w-full gap-4 items-center justify-start">
          <div className="h-[18px] w-[18px]">
            <img src={telegramLogo} className="h-full w-full" alt="Telegram" />
          </div>
          <div className="h-[18px] w-[18px]">
            <img src={githubLogo} className="h-full w-full" alt="Github" />
          </div>
          <div className="h-[18px] w-[18px]">
            <img src={discordLogo} className="h-full w-full" alt="Discord" />
          </div>
          <div className="h-[18px] w-[18px]">
            <img src={xLogo} className="h-full w-full" alt="X" />
          </div>
        </section>
      </div>
    </>
  )
}

export default Footer

/* eslint-disable react/react-in-jsx-scope */

import tooltip from '../../assets/tooptip.svg'
import avatar from '../../assets/avatar.png'
import edit from '../../assets/edit.svg'

const DashboardPage = () => {
  return (
    <div className="h-full flex flex-col gap-4 py-8 px-20 items-start justify-center">
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="w-16 h-16">
          <img src={avatar} alt="Profile" className="w-full h-full" />
        </div>
        <p className="font-Louize font-normal text-5xl tracking-tighter text-center text-stone-900">Hello, user</p>
        <button
          disabled={true}
          onClick={() => {}}
          className="w-fit flex items-center justify-center rounded-lg gap-1 py-1 px-2 bg-white"
        >
          <div className="w-4 h-4">
            <img src={edit} alt="Profile" className="w-full h-full" />
          </div>
          <p className="font-Suisse-Intl font-medium text-sm text-stone-800">Edit</p>
        </button>
      </div>

      <div className="h-fit flex flex-col gap-[7px] items-start justify-start">
        <div className="w-fit flex gap-2 items-center justify-center">
          <p className="font-Suisse-Intl font-medium text-sm text-stone-600">MINTED AND REDEEMED</p>
          <div className="w-6 h-6">
            <img src={tooltip} alt="Tooltip" className="w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage

export enum ETxnStatus {
  IDLE,
  PROCESSING,
  TXN_SUBMITTED_ON_CHAIN,
  TXN_SUCCESS,
  TXN_FAILURE,
}

export enum EApiCallStatus {
  IDLE,
  PROCESSING,
  API_CALL_SUCCESS,
  API_CALL_FAILURE,
}

export type TCollateral = {
  name: string
  symbol: string
  decimals: number
  iconPath?: string
  assetsIconName: string
  contractAddress: `0x${string}`
}

export type TSCREEN = 'input' | 'confirm' | 'success'

export type TMINT_SCREEN = TSCREEN

export type TSTAKE_TAB = 'stake' | 'unstake' | 'withdraw'

export type TSTAKE_SCREEN = TSCREEN

export type TMintOrder = {
  user: `${'0x'}string`
  collateralAmount: bigint
  usnAmount: bigint
  nonce: number
  expiry: number
  collateralAddress: `${'0x'}string`
}

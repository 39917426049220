import { TCollateral } from '../types'

export const SUSN_ADDRESS = '0x6c945b1e8aA77D122C3a14e29Fa5CacA805D6aD7'
export const USN_ADDRESS = '0x65666d1B3a40412a9849641f17e41e631509929d'
export const MINT_HANDLER_ADDRESS = '0xC517c403Cebeb728c9164C9e8A12d41ed49FCD90'
export const STAKING_VAULT_ADDRESS = '0x6c945b1e8aA77D122C3a14e29Fa5CacA805D6aD7'
export const MOCK_COLLATERAL_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7'

export const COLLATERALS: TCollateral[] = [
  {
    name: 'USDC',
    symbol: 'USDC',
    decimals: 6,
    iconPath: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    contractAddress: MOCK_COLLATERAL_ADDRESS,
    assetsIconName: 'usdc',
  },
  {
    name: 'USDT',
    symbol: 'USDT',
    decimals: 6,
    iconPath: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    contractAddress: MOCK_COLLATERAL_ADDRESS,
    assetsIconName: 'usdt',
  },
]

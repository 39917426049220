/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import { maxUint256, parseUnits, zeroAddress } from 'viem'
import { useEffect, useMemo, useState } from 'react'

import { waitForTransactionReceipt } from '@wagmi/core'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { STAKING_VAULT_ADDRESS, USN_ADDRESS } from '../../constants'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import { ETxnStatus, TSTAKE_SCREEN } from '../../types'
import ERC20ABI from '../../constants/abis/ERC20.json'
import arrowRight from '../../assets/arrow-right.svg'
import networkFee from '../../assets/networkFee.svg'
import USNs from '../../assets/USNs.svg'
import USN from '../../assets/USN.svg'

interface IProps {
  usnAmount: string
  setUSNAmount: (val: string) => void
  setUSNsAmount: (val: string) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
}

const InputAndApproveStake = (props: IProps) => {
  const [approveButtonText, setApproveButtonText] = useState<string>('Approve')
  const [approveTxnStatus, setApproveTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const usnBalance = useReadContract({
    abi: ERC20ABI,
    address: USN_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const usnAllowance = useReadContract({
    abi: ERC20ABI,
    address: USN_ADDRESS,
    functionName: 'allowance',
    args: [account.address ?? zeroAddress, STAKING_VAULT_ADDRESS],
    config: CHAIN_NETWORK_CONFIG,
  })

  const usnsOutputAmount = useReadContract({
    abi: StakingVaultABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'previewDeposit',
    args: [parseUnits(props.usnAmount ?? '0', 18)],
    config: CHAIN_NETWORK_CONFIG,
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.usnAmount ||
      !Number(props.usnAmount) ||
      parseUnits(props.usnAmount ?? '0', 18) > ((usnBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props, props.usnAmount],
  )

  const isUSNApprovalNeeded = useMemo(() => {
    return ((usnAllowance.data as bigint) ?? BigInt(0)) < parseUnits(props.usnAmount ?? '0', 18)
  }, [props, props.usnAmount])

  const formattedUSNBalance = useMemo(
    () => formatBigNumber((usnBalance.data as bigint) ?? BigInt(0), 18),
    [props, usnBalance, formatBigNumber],
  )

  const formattedUSNsOutputAmount = useMemo(
    () => formatBigNumber((usnsOutputAmount.data as bigint) ?? BigInt(0), 18),
    [props, formatStringToNumber, usnsOutputAmount],
  )

  useEffect(() => {
    props.setUSNsAmount(formatBigNumber((usnsOutputAmount.data as bigint) ?? BigInt(0), 18, false))
  }, [props, usnsOutputAmount])

  const onChangeUSNAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = (event?.target as HTMLInputElement)?.value
    if (value === '') {
      props.setUSNAmount('')
    }

    if (!value || !Number(value)) {
      return
    }

    props.setUSNAmount(value)
  }

  const setUSNAmount = (option: 'half' | 'max') => {
    const bigIntVal = (usnBalance.data as bigint) ?? BigInt(0)
    const optionBigIntval =
      option === 'half' ? bigIntVal / BigInt(2) : bigIntVal > BigInt(0) ? bigIntVal - BigInt(1) : BigInt(0)
    const value = formatBigNumber(optionBigIntval, 18, false)
    props.setUSNAmount(value)
  }

  const approveUSN = async () => {
    if (!account.isConnected) {
      setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      setApproveButtonText('Try again')
      return
    }

    setApproveTxnStatus(ETxnStatus.PROCESSING)

    writeContract(
      {
        abi: ERC20ABI,
        address: USN_ADDRESS,
        functionName: 'approve',
        args: [STAKING_VAULT_ADDRESS ?? zeroAddress, maxUint256],
      },
      {
        onError: () => {
          setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
          setApproveButtonText('Try again')
          setTimeout(() => {
            setApproveTxnStatus(ETxnStatus.IDLE)
            setApproveButtonText('Approve')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setApproveTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setApproveButtonText('Approving...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          if (receipt.status === 'success') {
            setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
            setApproveButtonText('Approved')
          } else {
            setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
            setApproveButtonText('Try again')
            setTimeout(() => {
              setApproveTxnStatus(ETxnStatus.IDLE)
              setApproveButtonText('Approve')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <>
      <div className="w-full flex flex-col items-start justify-between gap-1">
        <p className="w-full font-Suisse-Intl font-[450] text-base leading-6 text-stone-500">
          Enter the USN amount you would like to stake
        </p>
        <div className="w-full flex flex-col items-start justify-between gap-2">
          <div className="w-full flex rounded-lg border-[1px] p-4 gap-2 bg-stone-50 border-stone-200 items-center justify-between">
            <input
              name="stake-amount"
              id="stake-amount"
              type="text"
              className="outline-none font-Suisse-Intl font-[450] text-base leading-6 text-stone-900 bg-transaparent border-none grow border-2 border-stone-200"
              placeholder="Amount of USN to stake"
              value={props.usnAmount}
              onChange={onChangeUSNAmount}
            />
            <div
              id="stake-currency"
              className="cursor-pointer flex gap-[4px] rounded-[4px] py-[2px] px-[6px] items-center justify-between justify-self-end"
            >
              <div className="w-[18px] h-[18px] leading-6">
                <img src={USN} alt="Token image" className="w-full h-full" />
              </div>
              <p className="font-Suisse-Intl font-medium text-base leading-6 text-stone-600">USN</p>
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between">
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">Available: {formattedUSNBalance} USN</p>
            <div className="w-fit flex gap-1 items-center justify-between">
              <button
                onClick={() => setUSNAmount('half')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                HALF
              </button>
              <button
                onClick={() => setUSNAmount('max')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-1 justify-between items-center">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">You will receive</p>
        <div className="w-fit flex rounded gap-1 items-center justify-between">
          <div className="w-6 h-6 rounded-[100px]">
            <img src={USNs} className="w-full h-full" alt="USNs" />
          </div>
          <p className="font-Suisse-Intl font-semibold text-lg text-stone-950">{formattedUSNsOutputAmount} USNs</p>
        </div>
      </div>

      <hr className="w-full block border-[1px] border-stone-200" />

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USNs} className="w-full h-full" alt="USNs" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USNs equal to</p>
          </div>
          <div className="w-fit flex gap-2 items-center justify-center">
            <div className="w-fit flex rounded gap-1 items-center justify-between">
              <div className="w-[18px] h-[18px] rounded-[100px]">
                <img src={USN} className="w-full h-full" alt="USNs" />
              </div>
              <p className="font-Suisse-Intl font-semibold text-base text-stone-900">21 USN</p>
            </div>
            <p className="font-Suisse-Intl font-semibold text-base text-stone-500">•</p>
            <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
          </div>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USN} className="w-full h-full" alt="USN" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USN equal to</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-900">$100.21</p>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-5 h-5 rounded-[100px]">
              <img src={networkFee} className="w-full h-full" alt="Network fee" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Network Fee</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
        </div>
      </div>

      <button
        onClick={isUSNApprovalNeeded ? approveUSN : () => props.setScreen('confirm')}
        disabled={
          isActionDisabled ||
          approveTxnStatus === ETxnStatus.PROCESSING ||
          approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center`}
      >
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {isUSNApprovalNeeded ? approveButtonText : 'Next'}
        </p>
        <div className="w-6 h-6">
          <img src={arrowRight} className="w-full h-full" alt="Arrow" />
        </div>
      </button>
    </>
  )
}

export default InputAndApproveStake

import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { QueryClient } from '@tanstack/react-query'
import { sepolia, mainnet } from 'wagmi/chains'
import { http } from 'wagmi'

export const CHAIN_NETWORK_CONFIG = getDefaultConfig({
  appName: 'dclf-labs/NOON-Front',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '',
  chains: [sepolia, mainnet],
  transports: {
    [sepolia.id]: http(),
    [mainnet.id]: http(),
  },
})

export const QUERY_CLIENT = new QueryClient()

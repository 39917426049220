/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'

import { InputAndApproveMint, MintConfirm, MintSuccessful } from '../../components/Mint'
import ProtocolInfo from '../../components/ProtocolInfo/ProtocolInfo'
import { TCollateral, TMINT_SCREEN } from '../../types'
import { COLLATERALS } from '../../constants'

const MintingPage = () => {
  const [screen, setScreen] = useState<TMINT_SCREEN>('input')
  const [collateralAmount, setCollateralAmount] = useState<string>('')
  const [selectedCollateral, setSelectedCollateral] = useState<TCollateral>(COLLATERALS[0])

  return (
    <div className="h-fit flex flex-col md:grid md:grid-cols-2 gap-6 py-10 px-4 md:px-20 md:py-32 items-center justify-start md:items-start md:justify-between">
      <ProtocolInfo page="Minting" />
      <div className="w-full md:w-[73%] max-w-full flex flex-col py-4 px-3 md:p-6 gap-6 rounded-xl border-[1px] border-stone-300 bg-white mint-container-shadow justify-self-end">
        {screen === 'input' ? (
          <InputAndApproveMint
            setScreen={setScreen}
            collateralAmount={collateralAmount}
            selectedCollateral={selectedCollateral}
            selectCollateral={setSelectedCollateral}
            setCollateralAmount={setCollateralAmount}
          />
        ) : screen === 'confirm' ? (
          <MintConfirm
            setScreen={setScreen}
            collateralAmount={collateralAmount}
            selectedCollateral={selectedCollateral}
            selectCollateral={setSelectedCollateral}
            setCollateralAmount={setCollateralAmount}
          />
        ) : (
          <MintSuccessful
            setScreen={setScreen}
            collateralAmount={collateralAmount}
            selectedCollateral={selectedCollateral}
            selectCollateral={setSelectedCollateral}
            setCollateralAmount={setCollateralAmount}
          />
        )}
      </div>
    </div>
  )
}

export default MintingPage

/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import { parseUnits, zeroAddress } from 'viem'
import { useMemo, useState } from 'react'

import { waitForTransactionReceipt } from '@wagmi/core'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { STAKING_VAULT_ADDRESS, USN_ADDRESS } from '../../constants'
import doubleTick from '../../assets/check-tick-double.svg'
import ERC20ABI from '../../constants/abis/ERC20.json'
import { ETxnStatus, TMINT_SCREEN } from '../../types'
import networkFee from '../../assets/networkFee.svg'
import arrowLeft from '../../assets/arrow-left.svg'
import { formatStringToNumber } from '../../utils'
import USNs from '../../assets/USNs.svg'
import USN from '../../assets/USN.svg'

interface IProps {
  usnAmount: string
  usnsAmount: string
  setTxnExplorerURL: (url: string) => void
  setScreen: (screen: TMINT_SCREEN) => void
}

const StakeConfirm = (props: IProps) => {
  const [confirmButtonText, setConfirmButtonText] = useState<string>('Confirm')
  const [stakeStatus, setStakeStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const collateralBalance = useReadContract({
    abi: ERC20ABI,
    address: USN_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.usnAmount ||
      !props.usnsAmount ||
      !Number(props.usnAmount) ||
      !Number(props.usnsAmount) ||
      parseUnits(props.usnAmount ?? '0', 18) > ((collateralBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props],
  )

  const formattedUSNInputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnAmount) ? props.usnAmount : ''),
    [props, formatStringToNumber],
  )

  const formattedUSNsOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnsAmount) ? props.usnsAmount : ''),
    [props, formatStringToNumber],
  )

  const stakeUSN = async () => {
    if (!account.isConnected) {
      setStakeStatus(ETxnStatus.TXN_FAILURE)
      setConfirmButtonText('Try again')
      return
    }

    setStakeStatus(ETxnStatus.PROCESSING)

    writeContract(
      {
        abi: StakingVaultABI,
        address: STAKING_VAULT_ADDRESS,
        functionName: 'deposit',
        args: [parseUnits(props.usnAmount ?? '0', 18), account?.address],
      },
      {
        onError: () => {
          setStakeStatus(ETxnStatus.TXN_FAILURE)
          setConfirmButtonText('Try again')
          setTimeout(() => {
            setStakeStatus(ETxnStatus.IDLE)
            setConfirmButtonText('Confirm')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setStakeStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setConfirmButtonText('Confirming...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          if (receipt.status === 'success') {
            setStakeStatus(ETxnStatus.TXN_SUCCESS)
            setConfirmButtonText('Confirmed')
            props.setTxnExplorerURL(`${account.chain?.blockExplorers.default.url}/tx/${receipt.transactionHash}`)
            props.setScreen('success')
          } else {
            setStakeStatus(ETxnStatus.TXN_FAILURE)
            setConfirmButtonText('Try again')
            setTimeout(() => {
              setStakeStatus(ETxnStatus.IDLE)
              setConfirmButtonText('Confirm')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <>
      <button
        className="w-fit flex items-start justify-between rounded-[4px] gap-1"
        onClick={() => props.setScreen('input')}
      >
        <div className="h-6 w-6">
          <img src={arrowLeft} className="w-full h-full" alt="Arrow" />
        </div>
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">BACK</p>
      </button>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        Confirm your staking transaction
      </p>

      <div className="w-full rounded-xl p-2 bg-stone-100">
        <div className="w-full h-full flex flex-col md:flex-row items-center justify-between gap-2">
          <div className="grow w-full md:max-w-[49%] md:h-full rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">You&apos;re staking</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={USN} className="w-full h-full" alt="USN" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center">
                {formattedUSNInputAmount} USN
              </p>
            </div>
          </div>

          <div className="grow w-full md:max-w-[49%] md:h-full rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">To get back</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={USNs} className="w-full h-full" alt="USNs" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center">
                {formattedUSNsOutputAmount} USNs
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USNs} className="w-full h-full" alt="USNs" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USNs equal to</p>
          </div>
          <div className="w-fit flex gap-2 items-center justify-center">
            <div className="w-fit flex rounded gap-1 items-center justify-between">
              <div className="w-[18px] h-[18px] rounded-[100px]">
                <img src={USN} className="w-full h-full" alt="USNs" />
              </div>
              <p className="font-Suisse-Intl font-semibold text-base text-stone-900">21 USN</p>
            </div>
            <p className="font-Suisse-Intl font-semibold text-base text-stone-500">•</p>
            <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
          </div>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USN} className="w-full h-full" alt="USN" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USN equal to</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-900">$100.21</p>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-5 h-5 rounded-[100px]">
              <img src={networkFee} className="w-full h-full" alt="Network fee" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Network Fee</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
        </div>
      </div>

      <button
        onClick={stakeUSN}
        disabled={
          isActionDisabled || stakeStatus === ETxnStatus.PROCESSING || stakeStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center`}
      >
        <div className="w-6 h-6">
          <img src={doubleTick} className="w-full h-full" alt="Tick" />
        </div>
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {confirmButtonText}
        </p>
      </button>
    </>
  )
}

export default StakeConfirm

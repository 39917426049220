/* eslint-disable react/react-in-jsx-scope */
import lightModeLogo from '../../assets/light-theme-icon.svg'
import darkModelLogo from '../../assets/dark-theme-icon.svg'

interface IProps {
  classes: string
}

const ThemeMode = (props: IProps) => {
  return (
    <button
      className={`${props.classes} items-center justify-between gap-0.5 rounded-[48px] p-0.5 bg-stone-200 cursor-pointer`}
    >
      <div className="h-[28px] w-[28px] rounded-[100px] p-1">
        <img src={darkModelLogo} className="h-full w-full object-cover" alt="Dark Mode Logo" />
      </div>
      <div className="h-[28px] w-[28px] rounded-[100px] p-1 bg-stone-50">
        <img src={lightModeLogo} className="h-full w-auto" alt="Light Mode Logo" />
      </div>
    </button>
  )
}

export default ThemeMode

/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useReadContract, useSignTypedData } from 'wagmi'
import { parseUnits, zeroAddress } from 'viem'
import { useMemo, useState } from 'react'

import { EApiCallStatus, TCollateral, TMINT_SCREEN, TMintOrder } from '../../types'
import { MINT_HANDLER_ADDRESS, MOCK_COLLATERAL_ADDRESS } from '../../constants'
import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import doubleTick from '../../assets/check-tick-double.svg'
import ERC20ABI from '../../constants/abis/ERC20.json'
import networkFee from '../../assets/networkFee.svg'
import arrowLeft from '../../assets/arrow-left.svg'
import { formatStringToNumber } from '../../utils'
import USN from '../../assets/USN.svg'

interface IProps {
  collateralAmount: string
  selectedCollateral: TCollateral
  setScreen: (screen: TMINT_SCREEN) => void
  setCollateralAmount: (value: string) => void
  selectCollateral: (colateral: TCollateral) => void
}

const MintConfirm = (props: IProps) => {
  const [confirmButtonText, setConfirmButtonText] = useState<string>('Confirm')
  const [mintStatus, setMintStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { signTypedData } = useSignTypedData({ config: CHAIN_NETWORK_CONFIG })

  const collateralBalance = useReadContract({
    abi: ERC20ABI,
    address: props.selectedCollateral.contractAddress,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.collateralAmount ||
      !Number(props.collateralAmount) ||
      parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals) >
        ((collateralBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props, props.collateralAmount],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.collateralAmount) ? props.collateralAmount : ''),
    [props, formatStringToNumber],
  )

  const sendMintReq = async (order: TMintOrder, signature: string) => {
    await fetch(process.env.REACT_APP_MINT_API_URL ?? '', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...order,
        collateralAmount: parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals).toString(),
        usnAmount: parseUnits(props.collateralAmount ?? '0', 18).toString(),
        signature,
      }),
    })
      .then(
        (res) => {
          if (res.ok && res.status === 200) {
            setMintStatus(EApiCallStatus.API_CALL_SUCCESS)
            setConfirmButtonText('Confirmed')
            props.setScreen('success')
            return
          }
          throw new Error('Error')
        },
        (err) => {
          throw new Error('Error' + err?.message)
        },
      )
      .catch((err: Error) => {
        console.error('Error minting from MINT API:', err)
        setMintStatus(EApiCallStatus.API_CALL_FAILURE)
        setConfirmButtonText('Try again')
        setTimeout(() => {
          setMintStatus(EApiCallStatus.IDLE)
          setConfirmButtonText('Confirm')
        }, 30 * 1000)
      })
  }

  const signMintData = () => {
    if (!account.isConnected) {
      setMintStatus(EApiCallStatus.API_CALL_FAILURE)
      setConfirmButtonText('Try again')
      return
    }

    setMintStatus(EApiCallStatus.PROCESSING)

    const domain = {
      name: 'MinterHandler',
      version: '1',
      chainId: account.chainId,
      verifyingContract: MINT_HANDLER_ADDRESS as `0x${string}`,
    }

    const types = {
      Order: [
        { name: 'user', type: 'address' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralAmount', type: 'uint256' },
        { name: 'usnAmount', type: 'uint256' },
        { name: 'expiry', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
      ],
    }

    const order: TMintOrder = {
      user: account.address as `${'0x'}string`,
      collateralAmount: parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals),
      usnAmount: parseUnits(props.collateralAmount ?? '0', 18),
      nonce: Math.floor(Math.random() * 100000000000),
      expiry: Math.floor(Date.now() / 1000) + 3600,
      collateralAddress: MOCK_COLLATERAL_ADDRESS as `${'0x'}string`,
    }

    signTypedData(
      {
        domain,
        types,
        message: order,
        primaryType: 'Order',
      },
      {
        onError: () => {
          setMintStatus(EApiCallStatus.API_CALL_FAILURE)
          setConfirmButtonText('Try again')
          setTimeout(() => {
            setMintStatus(EApiCallStatus.IDLE)
            setConfirmButtonText('Confirm')
          }, 30 * 1000)
        },
        onSuccess: (data) => {
          setConfirmButtonText('Confirming...')
          sendMintReq(order, data)
        },
      },
    )
  }

  return (
    <>
      <button
        className="w-fit flex items-start justify-between rounded-[4px] gap-1"
        onClick={() => props.setScreen('input')}
      >
        <div className="h-6 w-6">
          <img src={arrowLeft} className="w-full h-full" alt="Arrow" />
        </div>
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">BACK</p>
      </button>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        Confirm your USN minting <br /> transaction
      </p>

      <div className="w-full rounded-xl p-2 bg-stone-100">
        <div className="w-full h-full flex flex-col md:flex-row items-center justify-between gap-2">
          <div className="grow w-full md:max-w-[49%] md:h-full rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">You minted</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={USN} className="w-full h-full" alt="USN" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center">
                {formattedUSNOutputAmount} USN
              </p>
            </div>
          </div>

          <div className="grow w-full md:max-w-[49%] md:h-full rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">For</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img
                  src={props.selectedCollateral.iconPath}
                  className="w-full h-full"
                  alt={props.selectedCollateral.symbol}
                />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center">
                {formattedUSNOutputAmount} {props.selectedCollateral.symbol}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USN} className="w-full h-full" alt="USN" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USN equal to</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-900">$100.21</p>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-5 h-5 rounded-[100px]">
              <img src={networkFee} className="w-full h-full" alt="Network fee" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Network Fee</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
        </div>
      </div>

      <button
        onClick={signMintData}
        disabled={isActionDisabled || mintStatus === EApiCallStatus.PROCESSING}
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center`}
      >
        <div className="w-6 h-6">
          <img src={doubleTick} className="w-full h-full" alt="Tick" />
        </div>
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {confirmButtonText}
        </p>
      </button>
    </>
  )
}

export default MintConfirm

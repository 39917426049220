/* eslint-disable react/react-in-jsx-scope */

import { useAccount, useReadContract } from 'wagmi'
import { zeroAddress } from 'viem'
import { useMemo } from 'react'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { STAKING_VAULT_ADDRESS } from '../../constants'
import { formatBigNumber } from '../../utils'

const StakeInfo = () => {
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })

  const usnsBalance = useReadContract({
    abi: StakingVaultABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const formattedUSNsBalance = useMemo(
    () => formatBigNumber((usnsBalance.data as bigint) ?? BigInt(0), 18),
    [usnsBalance, formatBigNumber],
  )

  return (
    <div className="w-full flex flex-col md:flex-row items-start justify-center md:items-center md:justify-between gap-4 p-4 rounded-t-xl border-none border-b-[1px] border-b-stone-200 bg-stone-100">
      <div className="grow flex flex-col items-start justify-between gap-1">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">YOUR STAKED AMOUNT</p>
        <p className="font-Suisse-Intl font-medium text-2xl text-stone-900">{formattedUSNsBalance} USNs</p>
      </div>
      <div className="grow flex flex-col items-start md:items-end justify-between gap-1">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">LAST WEEK APR</p>
        <p className="font-Suisse-Intl font-medium text-2xl text-green-600">12.4%</p>
      </div>
    </div>
  )
}

export default StakeInfo

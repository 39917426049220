/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import { maxUint256, parseUnits, zeroAddress } from 'viem'
import { useMemo, useState } from 'react'

import { waitForTransactionReceipt } from '@wagmi/core'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import { ETxnStatus, TCollateral, TMINT_SCREEN } from '../../types'
import chevronBigDown from '../../assets/chevron-big-down.svg'
import ERC20ABI from '../../constants/abis/ERC20.json'
import { MINT_HANDLER_ADDRESS } from '../../constants'
import arrowRight from '../../assets/arrow-right.svg'
import networkFee from '../../assets/networkFee.svg'
import toolTip from '../../assets/tooptip.svg'
import USN from '../../assets/USN.svg'

interface IProps {
  collateralAmount: string
  selectedCollateral: TCollateral
  setScreen: (screen: TMINT_SCREEN) => void
  setCollateralAmount: (value: string) => void
  selectCollateral: (colateral: TCollateral) => void
}

const InputAndApproveMint = (props: IProps) => {
  const [approveTxnStatus, setApproveTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [approveButtonText, setApproveButtonText] = useState<string>('Approve')

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const collateralBalance = useReadContract({
    abi: ERC20ABI,
    address: props.selectedCollateral.contractAddress,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const collateralAllowance = useReadContract({
    abi: ERC20ABI,
    address: props.selectedCollateral.contractAddress,
    functionName: 'allowance',
    args: [account.address ?? zeroAddress, MINT_HANDLER_ADDRESS],
    config: CHAIN_NETWORK_CONFIG,
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.collateralAmount ||
      !Number(props.collateralAmount) ||
      parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals) >
        ((collateralBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props, props.collateralAmount],
  )

  const isCollateralApprovalNeeded = useMemo(() => {
    return (
      ((collateralAllowance.data as bigint) ?? BigInt(0)) <
      parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals)
    )
  }, [props, props.collateralAmount])

  const formattedCollateralBalance = useMemo(
    () => formatBigNumber((collateralBalance.data as bigint) ?? BigInt(0), props.selectedCollateral.decimals),
    [props, collateralBalance, formatBigNumber],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.collateralAmount) ? props.collateralAmount : ''),
    [props, formatStringToNumber],
  )

  const onChangeCollatealAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = (event?.target as HTMLInputElement)?.value
    if (value === '') {
      props.setCollateralAmount('')
    }

    if (!value || !Number(value)) {
      return
    }

    props.setCollateralAmount(value)
  }

  const setCollateralAmount = (option: 'half' | 'max') => {
    const bigIntVal = (collateralBalance.data as bigint) ?? BigInt(0)
    const optionBigIntval =
      option === 'half' ? bigIntVal / BigInt(2) : bigIntVal > BigInt(0) ? bigIntVal - BigInt(1) : BigInt(0)
    const value = formatBigNumber(optionBigIntval, props.selectedCollateral.decimals, false)
    props.setCollateralAmount(value)
  }

  const approveCollateral = async () => {
    if (!account.isConnected) {
      setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      setApproveButtonText('Try again')
      return
    }

    setApproveTxnStatus(ETxnStatus.PROCESSING)

    writeContract(
      {
        abi: ERC20ABI,
        address: props.selectedCollateral.contractAddress,
        functionName: 'approve',
        args: [MINT_HANDLER_ADDRESS ?? zeroAddress, maxUint256],
      },
      {
        onError: () => {
          setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
          setApproveButtonText('Try again')
          setTimeout(() => {
            setApproveTxnStatus(ETxnStatus.IDLE)
            setApproveButtonText('Approve')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setApproveTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setApproveButtonText('Approving...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          if (receipt.status === 'success') {
            setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
            setApproveButtonText('Approved')
          } else {
            setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
            setApproveButtonText('Try again')
            setTimeout(() => {
              setApproveTxnStatus(ETxnStatus.IDLE)
              setApproveButtonText('Approve')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <>
      <div className="w-fit flex gap-[2px] p-[2px] rounded-xl bg-stone-100 items-center justify-between">
        <button className="w-fit rounded-[10px] py-2 px-4 mint-tab-shadow bg-stone-50 font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text">
          Mint
        </button>
        <button className="w-fit rounded-[10px] py-2 px-4 flex justify-between items-center gap-1">
          <p className="font-Suisse-Intl font-medium text-base leading-6 text-unselected-tab-text">Redeem</p>
          <div className="h-5 w-5">
            <img src={toolTip} className="w-full h-full" alt="Redeem Tooltip" />
          </div>
        </button>
      </div>

      <div className="w-full flex flex-col items-start justify-between gap-1">
        <p className="w-full font-Suisse-Intl font-[450] text-base leading-6 text-stone-500">
          Enter the amount you would like to mint
        </p>
        <div className="w-full flex flex-col items-start justify-between gap-2">
          <div className="w-full flex rounded-lg border-[1px] p-4 gap-2 bg-stone-50 border-stone-200 items-center justify-between">
            <input
              name="mint-amount"
              id="mint-amount"
              type="text"
              className="outline-none font-Suisse-Intl font-[450] text-base leading-6 text-stone-900 bg-transaparent border-none grow border-2 border-stone-200"
              placeholder="Amount to Mint"
              value={props.collateralAmount}
              onChange={onChangeCollatealAmount}
            />
            <div
              id="mint-currency"
              className="cursor-pointer flex gap-[2px] rounded-[100px] border-[1px] py-[2px] px-[6px] bg-white border-stone-200 items-center justify-between justify-self-end"
            >
              <div className="w-[18px] h-[18px] leading-6">
                <img
                  src={props.selectedCollateral.iconPath}
                  alt="Token image"
                  className="w-full h-full"
                  onError={() => import(`../../assets/${props.selectedCollateral.assetsIconName}`)}
                />
              </div>
              <p className="font-Suisse-Intl font-medium text-base leading-6 text-stone-600">
                {props.selectedCollateral.name}
              </p>
              <div className="w-[18px] h-[18px] leading-6">
                <img
                  src={chevronBigDown}
                  alt="Token image"
                  className="w-full h-full"
                  onError={() => import(`../../assets/${props.selectedCollateral.assetsIconName}`)}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between">
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">
              Available: {formattedCollateralBalance} {props.selectedCollateral.symbol}
            </p>
            <div className="w-fit flex gap-1 items-center justify-between">
              <button
                onClick={() => setCollateralAmount('half')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                HALF
              </button>
              <button
                onClick={() => setCollateralAmount('max')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-1 justify-between items-center">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">You will receive</p>
        <div className="w-fit flex rounded gap-1 items-center justify-between">
          <div className="w-6 h-6 rounded-[100px]">
            <img src={USN} className="w-full h-full" alt="USN" />
          </div>
          <p className="font-Suisse-Intl font-semibold text-lg text-stone-950">{formattedUSNOutputAmount} $USN</p>
        </div>
      </div>

      <hr className="w-full block border-[1px] border-stone-200" />

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USN} className="w-full h-full" alt="USN" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USN equal to</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-900">$100.21</p>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-5 h-5 rounded-[100px]">
              <img src={networkFee} className="w-full h-full" alt="Network fee" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Network Fee</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
        </div>
      </div>

      <button
        onClick={isCollateralApprovalNeeded ? approveCollateral : () => props.setScreen('confirm')}
        disabled={
          isActionDisabled ||
          approveTxnStatus === ETxnStatus.PROCESSING ||
          approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center`}
      >
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {isCollateralApprovalNeeded ? approveButtonText : 'Next'}
        </p>
        <div className="w-6 h-6">
          <img src={arrowRight} className="w-full h-full" alt="Arrow" />
        </div>
      </button>
    </>
  )
}

export default InputAndApproveMint

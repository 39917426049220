import ReactDOM from 'react-dom/client'
import React from 'react'

import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { QueryClientProvider } from '@tanstack/react-query'
import '@rainbow-me/rainbowkit/styles.css'

import { WagmiProvider } from 'wagmi'

import { CHAIN_NETWORK_CONFIG, QUERY_CLIENT } from './constants/chainNetworkConfig'
import reportWebVitals from './reportWebVitals'

import noiseAndTexture from './assets/noise-and-texture.png'
import background329 from './assets/background329.png'
import elipsis38 from './assets/ellipse-38.png'
import App from './components/App/App'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <WagmiProvider config={CHAIN_NETWORK_CONFIG}>
      <QueryClientProvider client={QUERY_CLIENT}>
        <RainbowKitProvider>
          <div className="relative w-full h-full bg-stone-50 z-0 overflow-hidden">
            <div className="absolute top-0 left-0 m-0 p-0 w-full h-[25%] md:w-[81%] md:h-[76%] z-10 background-image-329">
              <img src={background329} className="m-0 p-0 w-full h-full" />
            </div>
            <div className="absolute left-0 right-0 ml-auto mr-auto p-0 w-[890px] max-w-[890px] top:0 md:-top-52 z-20">
              <img src={elipsis38} className="m-0 p-0 w-full h-full" />
            </div>
            <img src={noiseAndTexture} className="absolute m-0 p-0 top-0 left-0 w-full h-full z-30" />
            <div className="relative top-0 left-0 w-full h-full m-0 p-0 z-40">
              <App />
            </div>
          </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

/* eslint-disable react/react-in-jsx-scope */
import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts'

interface IProps {
  data: {
    value: number
    label: string
  }[]
}

function InfoChart(props: IProps) {
  const data: IProps['data'] = props.data.length
    ? props.data
    : [
        {
          value: 4000,
          label: 'A',
        },
        {
          value: 3000,
          label: 'A',
        },
        {
          value: 2000,
          label: 'A',
        },
        {
          value: 2780,
          label: 'A',
        },
        {
          value: 1890,
          label: 'A',
        },
        {
          value: 2390,
          label: 'A',
        },
        {
          value: 3490,
          label: 'A',
        },
      ]

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Tooltip />
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#C5CAFF" stopOpacity={1} />
            <stop offset="95%" stopColor="#C5CAFF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="value" stroke="#6C77E9" fillOpacity={1} fill="url(#colorValue)" />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default InfoChart

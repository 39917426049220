/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'

import chevronBigDown from '../../assets/chevron-big-down.svg'
import chevronBigUp from '../../assets/chevron-big-up.svg'
import ProtocolInfoChart from './ProtocolInfoChart'

interface IProps {
  page: 'Minting' | 'Staking'
}

function ProtocolInfo(props: IProps) {
  const [tvlVisible, setTVLVisible] = useState<boolean>(false)
  const [chartVisible, setChartVisible] = useState<boolean>(false)

  const toggleTVLVisibility = () => {
    setTVLVisible((state) => !state)
  }

  const toggleChartVisibility = () => {
    setChartVisible((state) => !state)
  }

  return (
    <div className="w-full md:w-[73%] max-w-full flex flex-col gap-8 mt-10">
      <p className="w-full font-normal font-Louize text-[56px] leading-[68px] text-stone-600 tracking-tighter">
        {props.page}
      </p>
      <div className="w-full flex flex-col gap-4">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex gap-3 items-center justify-between">
            <p className="font-[450] text-stone-500 text-sm leading-5 text-left">TOTAL VALUE LOCKED</p>
            <button className="w-6 h-6" onClick={toggleTVLVisibility}>
              <img src={tvlVisible ? chevronBigUp : chevronBigDown} className="w-full h-full " alt="Up arrow" />
            </button>
          </div>
          <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">$2M</p>
        </div>

        <div className={`${tvlVisible ? 'flex' : 'hidden'} w-full h-[14px] gap-2`}>
          <div className="h-full w-[50%] bg-orange-600 rounded-[48px]"></div>
          <div className="h-full w-[50%] bg-stone-400 rounded-[48px]"></div>
        </div>

        <div className={`${tvlVisible ? 'flex' : 'hidden'} h-[14px] gap-2 items-center justify-start`}>
          <div className="h-full w-[5%] bg-orange-600 rounded-[48px]"></div>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">USN</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">•</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">50%</p>
          <div className="h-full w-[5%] bg-stone-400 rounded-[48px]"></div>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">USNs</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">•</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">50%</p>
        </div>
      </div>

      <div className="w-full flex flex-col gap-6">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex gap-3 items-center justify-between">
            <p className="font-[450] font-Suisse-Intl leading-5 text-stone-500 text-sm">LAST 7D USNS APR</p>
            <button className="w-6 h-6" onClick={toggleChartVisibility}>
              <img src={chartVisible ? chevronBigUp : chevronBigDown} className="w-full h-full " alt="Up arrow" />
            </button>
          </div>
          <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">3.1%</p>
        </div>

        <div className={`${chartVisible ? 'block' : 'hidden'} w-full rounded-lg border-[1px] border-stone-300`}>
          <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">10%</p>
          <div className="w-full h-32" id="staking-info-chart">
            <ProtocolInfoChart data={[]} />
          </div>
          <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">0%</p>
        </div>
      </div>
    </div>
  )
}

export default ProtocolInfo

/* eslint-disable react/react-in-jsx-scope */
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'

import burgerIconClose from '../../assets/burger-icon-close.svg'
import burgerIcon from '../../assets/burder-icon.svg'
import walletLogo from '../../assets/wallet-icon.svg'
import logout from '../../assets/log-out-right.svg'
import logo from '../../assets/logo.svg'
import ThemeMode from '../ThemeMode'

const Navbar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false)

  const toggleMenu = () => {
    setIsMobileNavOpen((state) => !state)
  }

  return (
    <>
      <nav className="flex flex-wrap items-center justify-between py-2 px-4 md:py-3 md:px-8">
        <section className="flex items-center justify-center gap-2 md:block md:w-[32%] md:max-w-[32%]">
          <div className="flex md:hidden">
            <button id="hamburger" onClick={toggleMenu} className="h-[24px] w-[24px]">
              {!isMobileNavOpen ? (
                <img className="w-full h-full object-cover" src={burgerIcon} alt="Mobile Menu Open" />
              ) : (
                <img className="w-full h-full object-cover" src={burgerIconClose} alt="Mobile Menu Close" />
              )}
            </button>
          </div>

          <div className="h-[18px] w-[64px] md:h-[24px] md:w-[81.45px]">
            <img src={logo} className="h-full w-full" alt="Noon Capital Logo" />
          </div>
        </section>

        <div className="hidden md:w-auto md:flex md:border-none md:gap-6 font-Suisse-Intl font-medium leading-6 md:justify-around md:items-center">
          <NavLink to="/" className={({ isActive }) => (!isActive ? 'text-stone-500' : 'text-stone-900')}>
            Minting
          </NavLink>
          <NavLink to="/staking" className={({ isActive }) => (!isActive ? 'text-stone-500' : 'text-stone-900')}>
            Staking
          </NavLink>
          <NavLink to="/dashboard" className={({ isActive }) => (!isActive ? 'text-stone-500' : 'text-stone-900')}>
            Dashboard
          </NavLink>
        </div>

        <section className="flex md:gap-4 md:items-center md:justify-end md:w-[32%] md:max-w-[32%]">
          <ThemeMode classes={'hidden md:flex'} />
          <ConnectButton.Custom>
            {({ account, chain, openChainModal, openAccountModal, openConnectModal, mounted }) => {
              const connected = mounted && account && chain

              return (
                <div
                  {...(!mounted && {
                    'aria-hidden': true,
                    style: {
                      opacity: 0,
                      pointerEvents: 'none',
                      userSelect: 'none',
                    },
                  })}
                >
                  {(() => {
                    if (!connected) {
                      return (
                        <button
                          onClick={openConnectModal}
                          className="flex items-center justify-around gap-1 md:gap-1.5 w-fit rounded-[184px] bg-indigo-600 py-1.5 px-3 md:py-3 md:px-[18px]"
                        >
                          <div className="h-[20px] w-[20px] md:h-[24px] md:w-[24px]">
                            <img src={walletLogo} className="h-full w-full object-cover" alt="Dark Mode Logo" />
                          </div>
                          <p className="text-stone-50 w-fit text-sm md:text-base leading-5 md:leading-6 font-bold font-Suisse-Intl cursor-pointer">
                            Connect wallet
                          </p>
                        </button>
                      )
                    }

                    if (chain.unsupported) {
                      return (
                        <button
                          onClick={openChainModal}
                          className="flex items-center justify-around gap-1 md:gap-1.5 w-fit rounded-[184px] bg-indigo-600 py-1.5 px-3 md:py-3 md:px-[18px]"
                        >
                          <div className="h-[20px] w-[20px] md:h-[24px] md:w-[24px]">
                            <img src={walletLogo} className="h-full w-full object-cover" alt="Dark Mode Logo" />
                          </div>
                          <p className="text-stone-50 w-fit text-sm md:text-base leading-5 md:leading-6 font-bold font-Suisse-Intl cursor-pointer">
                            Switch Network
                          </p>
                        </button>
                      )
                    }

                    return (
                      <button
                        onClick={openAccountModal}
                        className="flex items-center justify-around gap-1 md:gap-1.5 w-fit rounded-[184px] bg-stone-100 py-1.5 px-3 md:py-3 md:px-[18px] border-[1px] border-stone-300"
                      >
                        <div className="h-5 w-5 md:h-6 md:w-6">
                          <img src={logout} className="h-full w-full object-cover" alt="Dark Mode Logo" />
                        </div>
                        <p className="text-stone-900 w-fit text-sm md:text-base leading-5 md:leading-6 font-medium font-Suisse-Intl cursor-pointer">
                          {`${account.address.slice(0, 4)}...${account.address.slice(account.address.length - 5)}`}
                        </p>
                      </button>
                    )
                  })()}
                </div>
              )
            }}
          </ConnectButton.Custom>
        </section>
      </nav>

      {isMobileNavOpen && (
        <nav className="md:hidden p-2 mt-1">
          <div className="flex flex-col items-start justify-between border-[1px] rounded-xl p-2 gap-4 bg-stone-100 border-stone-300">
            <ThemeMode classes={'flex md:hidden'} />
            <div className="w-full flex flex-col items-start justify-between gap-4 md:hidden">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  'rounded-lg p-2 gap-2' + !isActive ? 'text-stone-500' : 'text-stone-900 bg-white'
                }
              >
                Minting
              </NavLink>
              <NavLink
                to="/staking"
                className={({ isActive }) =>
                  'rounded-lg p-2 gap-2' + !isActive ? 'text-stone-500' : 'text-stone-900 bg-white'
                }
              >
                Staking
              </NavLink>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  'rounded-lg p-2 gap-2' + !isActive ? 'text-stone-500' : 'text-stone-900 bg-white'
                }
              >
                Dashboard
              </NavLink>
            </div>
          </div>
        </nav>
      )}
    </>
  )
}

export default Navbar

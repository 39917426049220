/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useMemo, useState } from 'react'
import { useAccount, useReadContract } from 'wagmi'
import { parseUnits, zeroAddress } from 'viem'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import cross from '../../assets/multiple-cross-cancel-default.svg'
import infoCircle from '../../assets/information-circle.svg'
import { STAKING_VAULT_ADDRESS } from '../../constants'
import ERC20ABI from '../../constants/abis/ERC20.json'
import arrowRight from '../../assets/arrow-right.svg'
import networkFee from '../../assets/networkFee.svg'
import { TSTAKE_SCREEN } from '../../types'
import USNs from '../../assets/USNs.svg'
import USN from '../../assets/USN.svg'

interface IProps {
  usnsAmount: string
  setUSNAmount: (val: string) => void
  setUSNsAmount: (val: string) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
}

const InputAndApproveUnStake = (props: IProps) => {
  const [infoVisible, setToggleInfoVisibility] = useState<boolean>(true)

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })

  const usnsBalance = useReadContract({
    abi: ERC20ABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
  })

  const usnOutputAmount = useReadContract({
    abi: StakingVaultABI,
    address: STAKING_VAULT_ADDRESS,
    functionName: 'previewRedeem',
    args: [parseUnits(props.usnsAmount ?? '0', 18)],
    config: CHAIN_NETWORK_CONFIG,
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.usnsAmount ||
      !Number(props.usnsAmount) ||
      parseUnits(props.usnsAmount ?? '0', 18) > ((usnsBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props, props.usnsAmount],
  )

  const formattedUSNsBalance = useMemo(
    () => formatBigNumber((usnsBalance.data as bigint) ?? BigInt(0), 18),
    [props, usnsBalance, formatBigNumber],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatBigNumber((usnOutputAmount.data as bigint) ?? BigInt(0), 18),
    [props, formatStringToNumber, usnOutputAmount],
  )

  useEffect(() => {
    props.setUSNAmount(formatBigNumber((usnOutputAmount.data as bigint) ?? BigInt(0), 18, false))
  }, [props, usnOutputAmount])

  const onChangeUSNsAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = (event?.target as HTMLInputElement)?.value
    if (value === '') {
      props.setUSNsAmount('')
    }

    if (!value || !Number(value)) {
      return
    }

    props.setUSNsAmount(value)
  }

  const setUSNsAmount = (option: 'half' | 'max') => {
    const bigIntVal = (usnsBalance.data as bigint) ?? BigInt(0)
    const optionBigIntval =
      option === 'half' ? bigIntVal / BigInt(2) : bigIntVal > BigInt(0) ? bigIntVal - BigInt(1) : BigInt(0)
    const value = formatBigNumber(optionBigIntval, 18, false)
    props.setUSNsAmount(value)
  }

  return (
    <>
      {infoVisible && (
        <div className="w-full flex items-start justify-between rounded-[10px] p-3 gap-2 bg-stone-100">
          <div className="w-6 h-6 shrink-0">
            <img src={infoCircle} alt="Tooltip" className="w-full h-full" />
          </div>
          <div className="flex flex-col items-start justify-center gap-[2px] shrink">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-900 text-wrap">
              Unstake Request to be withdrawn in 7 days
            </p>
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap">
              There is a 7-day cooling off period for unstaking USNs. Your USN will be available to withdraw 7 days
              after you unstake your USNs.
            </p>
          </div>
          <button
            disabled={!infoVisible}
            onClick={() => setToggleInfoVisibility((visible: boolean) => !visible)}
            className="w-5 h-5 shrink-0"
          >
            <img src={cross} alt="Tooltip" className="w-full h-full" />
          </button>
        </div>
      )}

      <div className="w-full flex flex-col items-start justify-between gap-1">
        <p className="w-full font-Suisse-Intl font-[450] text-base leading-6 text-stone-500">
          Enter the USNs amount you would like to unstake
        </p>
        <div className="w-full flex flex-col items-start justify-between gap-2">
          <div className="w-full flex rounded-lg border-[1px] p-4 gap-2 bg-stone-50 border-stone-200 items-center justify-between">
            <input
              name="unstake-amount"
              id="unstake-amount"
              type="text"
              className="outline-none font-Suisse-Intl font-[450] text-base leading-6 text-stone-900 bg-transaparent border-none grow border-2 border-stone-200"
              placeholder="Amount of USNs to unstake"
              value={props.usnsAmount}
              onChange={onChangeUSNsAmount}
            />
            <div
              id="unstake-currency"
              className="cursor-pointer flex gap-[4px] rounded-[4px] py-[2px] px-[6px] items-center justify-between justify-self-end"
            >
              <div className="w-[18px] h-[18px] leading-6">
                <img src={USNs} alt="Token image" className="w-full h-full" />
              </div>
              <p className="font-Suisse-Intl font-medium text-base leading-6 text-stone-600">USNs</p>
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between">
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">Available: {formattedUSNsBalance} USN</p>
            <div className="w-fit flex gap-1 items-center justify-between">
              <button
                onClick={() => setUSNsAmount('half')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                HALF
              </button>
              <button
                onClick={() => setUSNsAmount('max')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-1 justify-between items-center">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">You will receive</p>
        <div className="w-fit flex rounded gap-1 items-center justify-between">
          <div className="w-6 h-6 rounded-[100px]">
            <img src={USN} className="w-full h-full" alt="USNs" />
          </div>
          <p className="font-Suisse-Intl font-semibold text-lg text-stone-950">{formattedUSNOutputAmount} USN</p>
        </div>
      </div>

      <hr className="w-full block border-[1px] border-stone-200" />

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USNs} className="w-full h-full" alt="USNs" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USNs equal to</p>
          </div>
          <div className="w-fit flex gap-2 items-center justify-center">
            <div className="w-fit flex rounded gap-1 items-center justify-between">
              <div className="w-[18px] h-[18px] rounded-[100px]">
                <img src={USN} className="w-full h-full" alt="USN" />
              </div>
              <p className="font-Suisse-Intl font-semibold text-base text-stone-900">21 USN</p>
            </div>
            <p className="font-Suisse-Intl font-semibold text-base text-stone-500">•</p>
            <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
          </div>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-[18px] h-[18px] rounded-[100px]">
              <img src={USN} className="w-full h-full" alt="USN" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USN equal to</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-900">$100.21</p>
        </div>

        <div className="w-full flex gap-6 items-center justify-between">
          <div className="w-fit flex rounded gap-1 items-center justify-between">
            <div className="w-5 h-5 rounded-[100px]">
              <img src={networkFee} className="w-full h-full" alt="Network fee" />
            </div>
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Network Fee</p>
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-500">$100.21</p>
        </div>
      </div>

      <button
        onClick={() => props.setScreen('confirm')}
        disabled={isActionDisabled}
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center`}
      >
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          Next
        </p>
        <div className="w-6 h-6">
          <img src={arrowRight} className="w-full h-full" alt="Arrow" />
        </div>
      </button>
    </>
  )
}

export default InputAndApproveUnStake

/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'

import InputAndApproveUnStake from './InputAndApproveUnStake'
import { TSTAKE_SCREEN, TSTAKE_TAB } from '../../types'
import UnStakeSuccessful from './UnStakeSuccessful'
import UnStakeConfirm from './UnStakeConfirm'

interface IProps {
  screen: TSTAKE_SCREEN
  setTab: (tab: TSTAKE_TAB) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
}

const Stake = (props: IProps) => {
  const [usnAmount, setUSNAmount] = useState<string>('')
  const [usnsAmount, setUSNsAmount] = useState<string>('')
  const [txnExplorerURL, setTxnExplorerURL] = useState<string>('')

  return props.screen === 'input' ? (
    <InputAndApproveUnStake
      usnsAmount={usnsAmount}
      setUSNAmount={setUSNAmount}
      setUSNsAmount={setUSNsAmount}
      setScreen={props.setScreen}
    />
  ) : props.screen === 'confirm' ? (
    <UnStakeConfirm
      usnAmount={usnAmount}
      usnsAmount={usnsAmount}
      setScreen={props.setScreen}
      setTxnExplorerURL={setTxnExplorerURL}
    />
  ) : (
    <UnStakeSuccessful
      setTab={props.setTab}
      usnAmount={usnAmount}
      usnsAmount={usnsAmount}
      setScreen={props.setScreen}
      txnExplorerURL={txnExplorerURL}
    />
  )
}

export default Stake
